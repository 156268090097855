import React from "react"

import "./Footer.scss"
import Hack20WordmarkSVG from "../../images/hack20_wordmark.svg"

export default () => (
  <footer>
    <div className="footer-links">
      <section>
        <h3>Contact</h3>
        <ul>
          <li>
            <a href="mailto:info@dubhacks.co">Email</a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://twitter.com/DubHacks"
            >
              Twitter
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.facebook.com/DubHacks.Co"
            >
              Facebook
            </a>
          </li>
        </ul>
      </section>
      <section>
        <h3>Event</h3>
        <ul>
          <li>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://static.mlh.io/docs/mlh-code-of-conduct.pdf"
            >
              Code of Conduct
            </a>
          </li>
        </ul>
      </section>
    </div>
    <div className="footer-footer">
      <div className="copyright">DubHacks © {new Date().getFullYear()}</div>
      <img alt="Hack'20" src={Hack20WordmarkSVG} />
    </div>
  </footer>
)
