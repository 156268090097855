import React, { useState } from "react"
import classNames from "classnames"
import Hamburger from "./svg/Hamburger"
import "./header.scss"
import MLH_Banner from "../images/MLH_banner.svg"

const Header = () => {
  const [open, setOpen] = useState(false)
  return (
    <header>
      <nav className="mobile-none">
        <a href="/">
          <h1 className="font-brand">
            Hack<span className="color-blue">'20</span>
          </h1>
        </a>
        <ul>
          <li>
            <a href="#tracks">Tracks</a>
          </li>
          <span className="spacer" role="presentation">
            ·
          </span>
          <li>
            <a href="#prizes">Prizes</a>
          </li>
          <span className="spacer" role="presentation">
            ·
          </span>
          <li>
            <a href="#speakers">Speakers</a>
          </li>
          <span className="spacer" role="presentation">
            ·
          </span>
          <li>
            <a id="dubhacksLink" href="https://dubhacks.co">
              DubHacks
            </a>
          </li>
        </ul>
      </nav>
      <nav className="mobile-only">
        <div id="mobile-header">
          <button
            id="mobile-nav-toggle"
            onClick={() => setOpen(!open)}
            className={classNames({ open })}
          >
            <Hamburger />
          </button>
          <a href="/">
            <h1 className="font-brand">
              Hack<span className="color-blue">'20</span>
            </h1>
          </a>
        </div>
        <ul id="mobile-navbar" className={classNames({ open })}>
          <li>
            <a href="#tracks">Tracks</a>
          </li>
          <li>
            <a href="#prizes">Prizes</a>
          </li>
          <li>
            <a href="#speakers">Speakers</a>
          </li>
          <li>
            <a href="https://dubhacks.co">DubHacks</a>
          </li>
        </ul>
      </nav>
      <img
        alt="Major League Hacking 2021 Season"
        src={MLH_Banner}
        className="mlh_trust"
      />
    </header>
  )
}

export default Header
