import React from "react"

// inline the svg so it loads instantly and also respects css
export default () => (
  <svg
    width="27"
    height="27"
    viewBox="0 0 27 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M8.33333 13H17.6667L18 13.3333V14.6667L17.6667 15H8.33333L8 14.6667V13.3333L8.33333 13Z"
        fill="black"
      />
      <path
        d="M8.33333 9H17.6667L18 9.33333V10.6667L17.6667 11H8.33333L8 10.6667V9.33333L8.33333 9Z"
        fill="black"
      />
      <path
        d="M8.33333 17H17.6667L18 17.3333V18.6667L17.6667 19H8.33333L8 18.6667V17.3333L8.33333 17Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="27" height="27" fill="black" />
      </clipPath>
    </defs>
  </svg>
)
